@import "./var.scss";
@import "./mixin.scss";
@import "./keyframes.scss";

.Search {
    // top: 16rem;
    position: relative;

    .searchForm {
        // transform: translateY(-7rem);\
        top: 0rem;
    }

    @media screen and (min-width: 567px) {
        .searchForm {
            // transform: translateY(-8rem);
            top: -5rem;
        }
    }

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
        gap: 1rem;
        top: 9rem;
        height: 0;

        .searchForm {
            display: flex;
            justify-content: center;
            height: fit-content;
            transform: translateY(-24rem);
        }
    }

}

.clearFilters {
    color: black;
    position: relative;
    font-size: 14px;
    border-radius: 10px;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    @include toAnim(0.3s);
    position: relative;
    cursor: pointer;
    margin: 0;
}

.area {
    width: 28px;
    height: 28px;

    @media screen and (min-width: 768px) {
        width: 19px;
        height: 19px;
    }
}

.searchingForm {
    width: 80%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
    /* background-color: rgba(white, 0.8); */
    background-color: white;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(237, 114, 3, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(237, 114, 3, 1);
    box-shadow: 0px 0px 10px 0px rgba(237, 114, 3, 1);
    box-shadow: 0px 0px 30px 0px rgba(237, 114, 3, 1);
    border-radius: 10px;
    border: 0px solid white;
    z-index: 1000;
    max-width: 100vw;
    @include toAnim(0.3s);

    /* cursor: zoom-in; */
    /* .wpSearching{
        width: 100%;
    } */
    .formGroup {
        width: fit-content;
    }

    .iconFlecha {
        opacity: 0;
        visibility: hidden;
        @include toAnim(0.3s);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .txt {
        width: fit-content;
        height: fit-content;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .wpLabel {
            width: fit-content;
            @include toAnim(0.3s);
        }

        label,
        input {
            @include toAnim(0.3s);
        }

        label {
            font-size: 16px;
            padding: 0 0.5vw;
            white-space: nowrap;
            text-transform: none !important;
            font-weight: 300;
            cursor: pointer;
            font-family: Work Sans;

            @media screen and (min-width: 768px) {
                font-size: 18px;
            }
        }

        input[type="text"] {
            border: 0;
            font-size: 0.8vw;
            padding: 0.5vw 0.5vw;

            &::-webkit-input-placeholder {
                /* Edge */
                color: $gris_3;
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $gris_3;
            }

            &::placeholder {
                color: $gris_3 !important;
            }
        }

        input[type="text"],
        textarea {
            @include toAnim(0.3s);
            border: 1px solid #ffffff;
            /* -webkit-box-shadow: 0px 0px 5px rgba(56, 169, 240, 0.75);
            -moz-box-shadow: 0px 0px 5px rgba(56, 169, 240, 0.75);
            box-shadow: 0px 0px 5px rgba(56, 169, 240, 0.75); */
        }

        input[type="text"]:focus,
        textarea:focus {
            /* @include toAnim(0.3s); */
            border: 1px solid $naranja;
            -webkit-box-shadow: 0px 0px 5px rgba(244, 121, 33, 0.75);
            -moz-box-shadow: 0px 0px 5px rgba(244, 121, 33, 0.75);
            box-shadow: 0px 0px 5px rgba(244, 121, 33, 0.75);
        }

        .optHide {
            visibility: hidden;
            opacity: 0;
            width: 100%;

            .small {
                font-size: 14px;

                .value {
                    font-size: 16px;
                }
            }

            @include toAnim(0.3s);
        }
    }
}

.rangeRoom {
    display: flex;
    justify-content: center;
    place-content: center;
    align-items: center;

    button {
        background-color: transparent;
        border: 0;
        font-size: 3rem;
    }

    .numberOfRoom {
        width: 5vw;
        font-size: 1.5rem;
        text-align: center;
    }

    &--inSection {
        height: 2rem;

        button {
            background-color: transparent;
            border: 0;
            font-size: 2rem;
        }

        .numberOfRoom {
            width: 3vw;
            font-size: 1rem;
            text-align: center;
        }
    }
}

.wpMultiRangeRange {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;

    .wpVal {
        width: 15%;

        span {
            font-size: 0.8vw;
            color: $naranja;
        }

        p {
            font-size: 1.1vw;
            font-weight: 700;
            color: $naranja;
            margin-bottom: 0;
        }
    }

    .wpMultiRange {
        width: 90%;
        padding: 0 5%;
    }
}

.claseButonVivenda {
    bottom: 22.5vw;
}

.claseButonhome {
    bottom: 7vw;
}

.formGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vw 1vw;
}

.icon {
    color: $naranja;
    padding-right: 0vw;
    line-height: 100%;
    display: flex;
    align-items: center;
    align-items: center;
    font-size: 16px;
    @include toAnim(0.3s);

    @media screen and (min-width: 768px) {
        font-size: 19px;
    }
}

.moreFilters {
    border: 0;
    font-size: 1.6vw;
    background-color: white;
    white-space: nowrap;

    span {
        font-size: 0.8vw;
        white-space: nowrap;
    }
}

.btnfilter {
    border: 0;
    white-space: nowrap;
    background-color: transparent;
    color: $gris;
    display: flex;
    align-items: center;

    span {
        color: $gris;
        font-size: 18px;
        padding: 0 4px;
    }
}

.positionSearch {
    visibility: hidden;
    opacity: 0;
}

.inputRango::placeholder {
    color: $gris_3 !important;
}

.errorPrecio {
    color: $ladrillo-oscuro;
    font-weight: 700;
}

/* nuevos estilos */

.iconFlecha {
    display: flex;
    justify-content: center;
    font-size: 2.3rem;
    color: $naranja;
    cursor: pointer;
}

.container_ciudades {
    /* max-height: 35vh; */
    overflow: auto;
}

.hfitContent {
    height: fit-content;
}

.containerOptionSection {
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    @include toAnim(0.3s);

    &.show {
        opacity: 1;
        visibility: visible;
        height: fit-content;
    }
}

.radio_group {
    display: flex;
    align-items: center;
    gap: 10px;

    .wpIcon {
        width: 10%;
    }

    .icon {
        font-size: 16px;
        font-size: 0.9vw;
        fill: $naranja;
    }

    input[type="radio"] {
        display: none;
    }

    label {
        display: block;
        cursor: pointer;
        transition:
            background-color 0.3s,
            color 0.3s;
        // font-size: 1.7vw;
        text-transform: none !important;
        color: $gris;
    }

    .location {
        width: 90%;
        /* width: fit-content; */
        /* font-size: 14px; */
        font-size: 1vw;
        font-family: Work Sans;
    }

    @media screen and (min-width: 768px) {
        gap: 20px;
    }

    /* @media screen and (min-width: 1280px) {
        .location {
            font-size: 24px;

        }
    } */

    input[type="radio"]:checked+label {
        color: $naranja;
    }
}

.ciudad_selec {
    white-space: nowrap;
    color: $gris;
    font-weight: 300;
    font-size: 22px;

    @media screen and (min-width: 1280) {
        font-size: 32px;
    }
}

// big desktop
@media only screen and (min-width: 1920px) {
    .claseButonVivenda {
        bottom: 32rem;
    }

    .claseButonhome {
        bottom: 5rem;
    }
}

@media only screen and (min-width: 1200px) {
    .topSearch {
        top: 10px;
        bottom: inherit;
        position: fixed;
    }
}

@media only screen and (max-width: 1200px) {}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {}

// tablet to desktop
@media screen and (min-width: 768px) {
    /* .optHide {
        width: 0%;
        visibility: hidden;
        overflow: hidden;
        @include toAnim(0.3s);
    } */

    .activeLoad {
        width: 0%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        background-color: white;
    }

    .activeLoad.Show {
        animation: activeLoad 0.8s ease-in-out 1;
        -webkit-animation: activeLoad 0.8s ease-in-out 1;
    }

    .active {
        width: 80%;
        position: absolute;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(255, 115, 1, 0.8);
        -moz-box-shadow: 0px 0px 10px 0px rgba(255, 115, 1, 0.8);
        box-shadow: 0px 0px 10px 0px rgba(255, 115, 1, 0.8);
        background-color: rgba(white, 1);
        cursor: default;
        padding-bottom: 2vw;
        top: 0rem !important;

        .txt {
            .wpLabel {
                top: 0;
            }

            &>label,
            &>span {
                font-size: 0.8vw;
            }

            input[type="text"] {
                font-size: 1.2vw;
            }

            .rango_input {
                input[type="text"] {
                    font-size: 1.2vw;
                }
            }

            .optHide {
                visibility: visible;
                opacity: 1;
            }
        }

        .icon {
            font-size: 1vw;
        }

        .iconFlecha {
            opacity: 1;
            visibility: visible;
        }
    }
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .searchingForm {
        top: -32rem;
    }

}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .searchingForm {}

    .topSearch {
        top: 10px;
        bottom: inherit;
        position: fixed;
    }
}

// big tablet LG
@media (max-width: 1200px) {
    .searchingForm {
        width: 95%;
    }

    .radio_group {
        .icon {
            font-size: 16px;
        }

        .location {
            font-size: 14px;
        }
    }

}

// tablet MD
@media (max-width: 767px) {

    .Search {
        top: 16rem;
        position: sticky;

    }

    .claseButonVivenda {
        bottom: 1.5rem;
    }

    .searchingForm {
        // position: fixed;
        position: absolute;
        width: 95%;
        margin: 0 auto;
        padding-right: 0;
        display: block;
        background-color: white;
        max-height: 100vh;
        overflow-y: auto;
    }

    .searchingForm.activeMobile {
        border: 3px solid $naranja;
        // transform: translateY(20rem);
        top: 0;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        padding-top: 3rem;
        height: fit-content;
    }

    .formGroup {
        padding: 0.5rem 0.5rem;
    }

    .icon {
        font-size: 1.8rem;
    }

    .moreFilters {
        font-size: 2rem;

        span {
            font-size: 1rem;
        }
    }

    .txt {
        width: 100%;

        label {
            font-size: 1rem;
        }

        input[type="text"] {
            font-size: 1.2rem;
            border: 1px solid $gris;
            padding: 0.3rem;
        }
    }

    .wpSearchMObile {
        height: 0;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        display: flex;
        align-content: flex-start;
        @include toAnim(0.3s);
    }

    .wpSearchMObile.active {
        height: fit-content;
        overflow: visible;
        opacity: 1;
        visibility: visible;
        background-color: white;
    }

    .btnBuscador {
        width: 100%;
        padding: 0.5rem 1rem;

        .textHiddentTime {
            position: relative;
            top: -1rem;
        }

        @media screen and (min-width: 760px) {
            padding-bottom: 1rem;
            padding-top: 1rem;
        }
    }

    .showSearchMObile {
        display: flex;
        align-items: center;
        justify-content: flex-end !important;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
        padding: 0.5rem 0.5rem;
        background-color: white;
        color: $naranja;
        font-size: 28px;
    }

    .btnfilter {
        width: 100%;
        font-size: 1.4rem;

        span {
            font-size: 1rem;
        }
    }

    .paddingMobile {
        @include toAnim(0.3s);
        padding: 1rem;
        position: absolute;
        top: 0;
        right: 0;
    }

    /*para volverla a su forma inicial comentar esto*/
    .topSearch {
        top: 10px;
        bottom: inherit;
        position: fixed;
    }

    @media (max-height: 570px) {
        .searchingForm {
            position: fixed;
        }
    }
}

// only mobile
@media (max-width: 768px) {
    .bottomAction {
        top: -28rem;
    }

    /*para volverla a su forma inicial comentar esto*/
    .topSearch {
        top: 130px;
    }

    .searchingForm {
        z-index: 999;
    }
}

// only mobile iphone 13

@media (max-width: 300px) {
    .searchingForm {
        width: 99%;
    }
}
@import "./var.scss";
@import "./mixin.scss";

@media (max-width: 767px) {
	.bannerContainer {
		margin-right: auto;
		margin-left: auto;
	}
}

//first desktop
.filterHome {
	top: 13rem;
	transform: translateY(-5rem);

	.container {
		position: absolute;
		top: -10rem;
		left: 0;
		width: 100%;
	}

	@media screen and (min-width: 567px) {
		top: 11rem;
	}

	@media screen and (min-width: 767px) {
		top: 8rem;
		transform: translateY(0rem);
	}
}

.filterVivienda {
	top: 13rem;
	transform: translateY(-5rem);


	.container {
		position: absolute;
		top: -10rem;
		left: 0;
		width: 100%;
	}

	@media screen and (min-width: 567px) {
		top: 7rem;
		transform: translateY(0rem);
	}

	@media screen and (min-width: 767px) {
		top: 5rem;
		transform: translateY(2rem);
	}

	@media screen and (min-width: 1200px) {
		top: 8rem;
		transform: translateY(0rem);
	}
}

.toAnim {
	@include toAnim(0.3s);
}

.minHeightSection {
	min-height: 56vw;
}

.linkoago {
	text-decoration: none;
	display: flex;
	align-items: center;
}

.arrow,
.smallArrow {
	border: 1px;
	background-color: transparent;
	font-size: 3vw;
	display: inline-block;
	color: $gris;
	line-height: 100%;
	cursor: pointer;
}

.smallArrow {
	font-size: 1.8vw;
}

.iconCircle {
	width: 3vw;
	height: 3vw;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	font-size: 2vw;
	background-color: white;
	box-shadow: 0px 0px 15px 0px #f4792140;
	-webkit-box-shadow: 0px 0px 15px 0px #f4792140;
}

.containerwpPhone {
	.play {
		opacity: 0;
		visibility: hidden;
	}

	&:hover {
		.play {
			opacity: 1;
			visibility: visible;
		}
	}
}

.lightwidgetWidget {
	width: 100%;
	height: 20vw;
	border: 0;
	overflow: hidden;
}

.py_comprarexterior {
	position: relative;
	padding: 2.2vw 0vw;
}

.fomulario {
	position: -webkit-sticky;
	position: sticky;
	top: 0.5vw;
	margin-top: 0vw;
	z-index: 99;
	//margin-bottom: 2vw;
	//height: 100%;
	//max-height: 100vh;
	//overflow-y: auto;
}

.py_titulo {
	padding: 0.5vw 1.5vw;
}

.py_btn {
	position: relative;
	padding-bottom: 2.5vw;
}

.btn_scroll {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bx_shadow {
	box-shadow: 0 1px 5px rgb(0, 0, 0, 0.25);
}

.cuadroPolitica {
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	padding: 2.5vw 3vw;
}

.borderNaranja {
	border: 3px solid $naranja;
	min-height: 21vw;
	// background-color: white;
	height: 100%;
}

.borderRadius10 {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	// overflow: hidden;
}

.containerCardNoticias {
	cursor: pointer;
	position: relative;
}

.Link_red {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.borderRadius20 {
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	overflow: hidden;
}

.pButton {
	padding: 0.5vw 6vw;
}

.sizeIcon {
	width: 2.6vw;
	height: 2.6vw;
	background-color: white;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5vw;
}

.circleIcon {
	max-width: initial;
	/* Ajusta el tamaño del icono dentro del círculo */
	height: auto;
}

.imgSecre {
	width: 37vw;
}

.minw {
	width: min-content;
}

.boxShadowOrange {
	-webkit-box-shadow: 0px 0px 10px 3px rgba(244, 121, 33, 0.95);
	-moz-box-shadow: 0px 0px 10px 3px rgba(244, 121, 33, 0.95);
	box-shadow: 0px 0px 10px 3px rgba(244, 121, 33, 0.95);
}

.imgAbsolute {
	position: static;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.wpcontrolM {
	width: 5vw;
}

.wpcontrol {
	width: 10vw;
}

.wpparrafo {
	width: 22%;
}

.hr {
	color: #e6e7e9;
}

.asociacion {
	box-shadow: 0px 0px 10px 0px #5954561a;
	padding: 2vw;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.piebanco {
	display: flex;
	justify-content: center;
	width: 100%;
}

.hBox {
	height: 24vw;
}

.btnenlace {
	width: 1.5vw;
	height: 1.5vw;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5vw;
	line-height: 100%;
}

.iconLink {
	font-size: 1.5vw;
	display: inline-flex;
}

.iconimg,
.iconpagos {
	line-height: 100%;
}

.dWrap {
	display: flex;
	flex-wrap: wrap;
}

.wpWrap {
	width: 60%;
}

.wAsk {
	width: 23%;
}

.items_gestion {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.tituloUser {
	border-bottom: 1px solid $gris-claro;
	border-top: 1px solid $gris-claro;
	padding-bottom: 1vw;
	padding-top: 2vw;
}

.wpgestioncomponent {
	min-width: 25%;
}

.tamaIcon {
	width: 4vw;
}

.soliIcon {
	img {
		width: 2.7vw !important;
	}
}

.wpIcongcodigo {
	width: 2vw;
}

.wpSection_informacion {
	width: 45%;
}

.body_gestioncodigo {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.cuadroResumenPago {
	border: 1px solid #e6e7e9;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	width: 100%;
	overflow: auto;
	/* Track 
		&::-webkit-scrollbar-track {
			background-color: rgba($naranja, 0.2);
		}*/

	/* Handle 
		&::-webkit-scrollbar-thumb {
			background-color: rgba($naranja, 0.2);
			border-radius: 10px 10px 10px 10px;
			-moz-border-radius: 10px 10px 10px 10px;
			-webkit-border-radius: 10px 10px 10px 10px;
	
		}*/

	/* Handle on hover 
		&::-webkit-scrollbar-thumb:hover {
			background-color: rgba($naranja, 0.4);
	
		}*/
}

.imgPortada {
	// -webkit-border-radius: 20px;
	// -moz-border-radius: 20px;
	// border-radius: 20px;
}

.imgMap {
	width: 12vw;
}

.arrowderecha {
	position: relative;
	margin-left: 2vw;
}

.arrowizquierda {
	position: relative;
	margin-right: 2vw;
}

.text_2line {
	overflow: hidden;
	text-overflow: ellipsis;
	/* Esto agrega puntos suspensivos (...) si el texto es truncado */
	display: -webkit-box;
	-webkit-line-clamp: 2;
	/* Número de líneas a mostrar */
	-webkit-box-orient: vertical;
	white-space: normal;
}

.element_urbanismo {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.element_cols {
	width: 33.33%;
}

.paddingContainer {
	padding-left: 2.3vw;
	padding-right: 2.3vw;
}

.contentLoaderGeneral {
	height: 60vh;
}

.wpItemscontactos {
	width: 25%;
}

.iframe_doc {
	height: 52vh;
}

.mt_loader {
	position: relative;
	margin-top: 7vw;
}

.anchoContainer {
	width: 35%;
}

.whImgan_macroCliente {
	width: 48%;
}

.claseButonhome {
	bottom: 23vw;
}

.claseButonVivenda {
	bottom: 22.5vw;
}

.controlesHome {
	display: flex;
	align-items: center;
	justify-content: center;
}

.vw_containerEspeciales {
	width: 90%;
}


.custom_checkbox_list {
	max-height: 30vh;
	overflow-y: auto;

	.checkbox_itemt {
		display: flex;
		align-items: center;
		padding: 0.4vw;
		cursor: pointer;

		span {
			text-transform: capitalize;
		}

		input[type="checkbox"] {
			margin-right: 0.4vw;
			width: 1.2vw;
			height: 1.2vw;
		}

		&:hover {
			background-color: $gris;
			color: white;
		}
	}
}

//big desktop
@media (min-width: 1920px) {
	.fomulario {
		position: -webkit-sticky;
		position: sticky;
		top: 2rem;
		margin-top: 0vw;
		z-index: 99;
	}

	.claseButonVivenda {
		bottom: 32rem;
	}

	.claseButonhome {
		bottom: 5rem;
	}
}

//big desktop
@media (min-width: 1920px) {
	.custom_checkbox_list {
		max-height: 30vh;
		overflow-y: auto;

		.checkbox_itemt {

			padding: 0.4rem;

			input[type="checkbox"] {
				margin-right: 0.4rem;
				width: 1rem;
				height: 1rem;
			}
		}
	}

	.imgMap {
		width: 25rem;
	}

	.cuadroPolitica {
		width: 85%;
	}

	.imgPortada {
		max-height: 50rem;
	}
}

//desktop
@media (min-width: 1200px) {
	.iframe_doc {
		height: 22rem;
	}

	.imgPortada {
		max-height: 100vw;
	}
}

@media (max-width: 1200px) {
	.iconCircle {
		width: 3rem;
		height: 3rem;
		font-size: 2rem;
	}

	.modalManual {
		//width: 70%;
		max-width: 90%;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
		@include toAnim(0.3s);
		background-color: white;
		opacity: 0;
		visibility: hidden;
		padding: 1.5rem;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		box-shadow: 0 1px 5px rgb(0, 0, 0, 0.25);
	}

	.showManualModal {
		opacity: 1;
		visibility: visible;
	}

	.cuadro {
		padding: 0.3rem 2.5rem;
		width: 100%;

		.iconcuadro {
			font-size: 2.5rem;
		}
	}
}

.hpvideo {
	height: 28vw;
}

@media (max-width: 992px) {
	.borderNaranja {
		border: none;
	}

	.boxShadowOrange {
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}

	.imgMap {
		width: 10rem;
	}
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.custom_checkbox_list {
	

		.checkbox_itemt {

			padding: 0.4rem;

			input[type="checkbox"] {
				margin-right: 0.4rem;
				width: 1.2rem;
				height: 1.2rem;
			}
		}
	}

	.filterHome {
		transform: translateY(4.5rem);

	}

	.whImgan_macroCliente {
		width: 100%;
	}

	.iframe_doc {
		height: 22rem;
	}

	.paddingContainer {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.cuadroPolitica {
		width: 90%;
		padding: 2.5rem 3rem;
	}

	.element_cols {
		width: 40%;
	}

	.hpvideo {
		height: 20rem;
	}

	.body_gestioncodigo {
		display: flex;
		flex-direction: column-reverse;
		align-items: flex-end;
	}

	.wpIcongcodigo {
		width: 2rem;
	}

	.wpSection_informacion {
		width: 80%;
	}

	.tamaIcon {
		width: 4rem;
		display: flex;
		justify-content: center;
	}

	.soliIcon {
		img {
			width: 2.7rem !important;
		}
	}

	.cuadro {
		padding: 0.3rem 2.5rem;
		width: 100%;

		.iconcuadro {
			font-size: 2.5rem;
		}
	}

	.asociacion {
		padding: 2rem;
	}

	.btnenlace {
		width: 1.5rem;
		height: 1.5rem;
		font-size: 1.5rem;
	}

	.iconLink {
		font-size: 1.5rem;
	}

	.wpWrap {
		width: 100%;
	}

	.arrow {
		font-size: 2.5rem;
	}

	.borderNaranja {
		min-height: 19rem;
	}

	.fomulario {
		top: 2rem;
	}
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.custom_checkbox_list {
	

		.checkbox_itemt {

			padding: 0.4rem;

			input[type="checkbox"] {
				margin-right: 0.4rem;
				width: 1.2rem;
				height: 1.2rem;
			}
		}
	}

	.filterHome {
		transform: translateY(3rem);
	}

	.filterVivienda {
		transform: translateY(5.5rem);
	}

	.iframe_doc {
		height: 22rem;
	}

	.fomulario {
		top: 2rem;
	}

	.cuadroPolitica {
		width: 90%;
		padding: 2.5rem 3rem;
	}

	.hpvideo {
		height: 25rem;
	}

	.wpSection_informacion {
		width: 50%;
	}

	.wpWrap {
		width: 100%;
	}

	.arrow {
		font-size: 2.5rem;
	}

	.borderNaranja {
		min-height: 16rem;
	}
}

@media(min-width: 768px) {

	.filterVivienda,
	.filterHome {
		height: 0;
	}
}

@media (max-width: 768px) {
	.controlesHome {
		flex-wrap: wrap;
	}

	.fomulario {
		top: 2rem;
	}

	.tamaIcon {
		width: 4rem;
	}

	.soliIcon {
		img {
			width: 2.7rem !important;
		}
	}

	.wpgestioncomponent {
		min-width: 40%;
	}

	.wpWrap {
		width: 100%;
	}

	.iconLink {
		font-size: 1.5rem;
	}

	.wpparrafo {
		width: 80%;
	}

	.cuadro {
		padding: 0.3rem 2.5rem;
		width: 100%;

		.iconcuadro {
			font-size: 2.5rem;
		}
	}

	.modalManual {
		width: 80%;
		text-align: center;
	}

	.wpcontrolM {
		width: auto;
	}

	.wpcontrol {
		width: auto;
	}

	.asociacion {
		padding: 2rem;
	}

	.hBox {
		height: auto;
	}

	.imgSecre {
		width: 23rem;
	}

	.btnenlace {
		width: 1.5rem;
		height: 1.5rem;
		font-size: 1.5rem;
	}

	.wAsk {
		width: 50%;
	}

	.wpIcongcodigo {
		width: 2rem;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.custom_checkbox_list {
		

		.checkbox_itemt {

			padding: 0.4rem;

			input[type="checkbox"] {
				margin-right: 0.4rem;
				width: 1rem;
				height: 1rem;
			}
		}
	}

	.vw_containerEspeciales {
		width: 100%;
	}

	.filterHome {
		transform: translateY(-1rem);
	}

	.paddingContainer {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.hpvideo {
		height: 25rem;
	}

	.wpSection_informacion {
		width: 60%;
	}

	.arrow {
		font-size: 2rem;
	}

	.borderNaranja {
		min-height: 21rem;
	}

	.cuadroPolitica {
		width: 90%;
		padding: 2.5rem 3rem;
	}

	.whImgan_macroCliente {
		width: 100%;
	}

	.claseButonhome {
		bottom: 3rem;
	}

	@media (max-height: 814px) {
		.claseButonhome {
			bottom: 18rem;
		}
	}

	@media (max-height: 700px) {
		.claseButonhome {
			bottom: 15rem;
		}
	}

	@media (max-height: 620px) {
		.claseButonhome {
			bottom: 2rem;
		}
	}
}

//only phone
@media (max-width: 576px) {
	.custom_checkbox_list {
	
		.checkbox_itemt {

			padding: 0.4rem;

			input[type="checkbox"] {
				margin-right: 0.4rem;
				width: 1rem;
				height: 1rem;
			}
		}
	}

	.vw_containerEspeciales {
		width: 100%;
	}

	.vw_containerEspeciales {
		width: 100%;
	}

	.lightwidgetWidget {
		height: 50vw;
	}

	.controlesHome {
		border-bottom: 2px solid #f47921;
	}

	.claseButonhome {
		bottom: 19rem;
	}

	.whImgan_macroCliente {
		width: 100%;
	}

	.anchoContainer {
		width: 100%;
	}

	.iframe_doc {
		height: 22rem;
	}

	.paddingContainer {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.cuadroPolitica {
		width: 90%;
		padding: 1.5rem 2rem;
	}

	.element_cols {
		width: 42%;
	}

	.hpvideo {
		height: 25rem;
	}

	.borderNaranja {
		min-height: 19rem;
	}

	.body_gestioncodigo {
		display: flex;
		flex-direction: column-reverse;
		align-items: flex-end;
	}

	.wpIcongcodigo {
		width: 2rem;
	}

	.wpSection_informacion {
		width: 100%;
	}

	.wpgestioncomponent {
		min-width: 100%;
	}

	.tituloUser {
		padding-bottom: 1rem;
		padding-top: 2rem;
	}

	.wpcontrolM {
		width: 6rem !important;
	}

	.wpWrap {
		width: 100%;
	}

	.iconLink {
		font-size: 1.5rem;
	}

	.btnenlace {
		width: 1.5rem;
		height: 1.5rem;
		font-size: 1.5rem;
	}

	.opacity {
		max-height: 0px;
		opacity: 0;
		visibility: hidden;
		@include toAnim(0.3s);
	}

	.opacity.active {
		max-height: 500px;
		opacity: 1;
		visibility: visible;
		@include toAnim(0.3s);
	}

	.iconAcordion {
		font-size: 2rem;
	}

	.iconCircle {
		width: 4rem;
		height: 4rem;
		font-size: 2.5rem;
	}

	.py_comprarexterior {
		padding: 2rem 0vw;
	}

	.arrow {
		font-size: 2rem;
	}

	.smallArrow {
		font-size: 1.5rem;
	}

	.py_btn {
		position: relative;
		padding-bottom: 2rem;
	}

	.modalManual {
		width: 80%;
		text-align: center;
	}

	.wpparrafo {
		width: 100%;
	}

	.cuadro {
		padding: 0.3rem 2.5rem;
		width: 100%;

		.iconcuadro {
			font-size: 2.5rem;
		}
	}

	.asociacion {
		padding: 2rem;
	}

	.asociacion {
		padding: 2rem;
	}

	.imgSecre {
		width: 18rem;
	}

	.wpItemscontactos {
		width: 30%;
	}

	@media (max-height: 814px) {
		.claseButonhome {
			bottom: 18rem;
		}
	}

	@media (max-height: 700px) {
		.claseButonhome {
			bottom: 10rem;
		}
	}

	@media (max-height: 660px) {
		.claseButonhome {
			bottom: 14rem;
		}
	}

	@media (max-height: 620px) {
		.claseButonhome {
			bottom: 2rem;
		}
	}
}

//only phone
@media (max-width: 300px) {
	.arrowderecha {
		margin-left: 1rem;
		display: none;
	}

	.arrowizquierda {
		margin-right: 1rem;
		display: none;
	}
}